import { combineReducers } from 'redux'

import report from './report'
import website from './website'
import websites from './websites'
import document from './document'
import getWebsiteCheckHistory from './getWebsiteCheckHistory'
import postWebsiteCheckHistory from './postWebsiteCheckHistory'
import putChangePassword from './putChangePassword'
import getDocumentCheckHistory from './getDocumentCheckHistory'
import postDocumentCheckHistory from './postDocumentCheckHistory'
import style from './style'
import billing from './billing'
import ip from './ip'
import templates from './templates'
import cookie from './cookie'
import planInfo from './planInfo'
import putPublishCookiePolicy from './putPublishCookiePolicy'
import getBannerCopy from './getBannerCopy'
import getApiToken from './getApiToken'
import putMigratedDocument from './putMigratedDocument'
import consentWidgetSettings from './consentWidgetSettings'
import snippetWebsite from './snippetWebsite'

export default combineReducers({
  document,
  website,
  websites,
  report,
  getWebsiteCheckHistory,
  postWebsiteCheckHistory,
  getDocumentCheckHistory,
  postDocumentCheckHistory,
  putChangePassword,
  putPublishCookiePolicy,
  style,
  billing,
  ip,
  templates,
  cookie,
  planInfo,
  getBannerCopy,
  getApiToken,
  putMigratedDocument,
  consentWidgetSettings,
  snippetWebsite,
})
