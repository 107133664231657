import createActionCreator from 'utils/redux'

export const UPDATE_LOADING = `${ __filename }/UPDATE_LOADING`
export const UPDATE_IS_LOADING_BTN_GENERATE_COOKIE_POLICY = `${ __filename }/UPDATE_IS_LOADING_BTN_GENERATE_COOKIE_POLICY`

export const updateLoading = createActionCreator(UPDATE_LOADING)
export const updateIsLoadingBtnGenerateCookiePolicy = createActionCreator(UPDATE_IS_LOADING_BTN_GENERATE_COOKIE_POLICY)

export const initState = {
  // Do not initialize to `false`--that will only confuse the code in
  // selectors/panels/scanReport.js which is trying to determine when
  // to show the "loading" spinner on the page. If you set this to
  // `false`, we'll get ugly flashes of content on ScanReportPage.
  loading: void 0,
  isLoadingBtnGenerateCookiePolicy: false,
}

export default (state = initState, { type, payload }) => {
  switch (type) {
    case UPDATE_LOADING:
      return {
        ...state,
        loading: payload,
      }
    case UPDATE_IS_LOADING_BTN_GENERATE_COOKIE_POLICY:
      return {
        ...state,
        isLoadingBtnGenerateCookiePolicy: payload,
      }
    default:
      return state
  }
}
