import {
  useContext,
  useMemo,
} from 'react'

import FeatureFlagsContext from '../FeatureFlagsContext'


export default function useFeatureFlags({ flags, traits }) {
  const flagsmith = useContext(FeatureFlagsContext)

  return useMemo(() => {
    const result = {}

    for ( const flag of flags ) {
      if ( !flag ) {
        continue
      }

      result[flag] = {
        enabled: flagsmith.hasFeature(flag),
        value: flagsmith.getValue(flag),
      }
    }

    if ( traits ) {
      for ( const trait of traits ) {
        if ( !trait ) {
          continue
        }

        result[trait] = flagsmith.getTrait(trait)
      }
    }

    return result
  }, [flags, flagsmith, traits])
}
