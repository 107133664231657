import { takeLatest } from 'redux-saga/effects'

import { resetPwd } from 'constants/api'

import API from 'utils/api'

import {
  RESET_PASSWORD,
  sendApiSuccess,
} from 'reducers/resetPwd'

import apiCall from './apiCall'


export const resetUserPassword = (action) => {
  return apiCall(action, API.postData, resetPwd, sendApiSuccess)
}

export function* watchResetUserPassword() {
  yield takeLatest(RESET_PASSWORD, resetUserPassword)
}
