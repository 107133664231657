import { useQuery } from '@tanstack/react-query'

import getIntegrationPartnerPlan from './getIntegrationPartnerPlan'

const CACHE_KEY = ['integration-partner-plan-details']


export default function useIntegrationPartnerPlanQuery(options) {
  const {
    data,
    ...rest
  } = useQuery(CACHE_KEY,
               getIntegrationPartnerPlan,
               options)

  const planDetails = data?.data

  return {
    planDetails,
    ...rest,
  }
}
