import { Client } from '@termly_web/common'

const BASE_URL = '/integrations/duda/api'
const GET_URI = 'v1/app/info'


export default function getIntegrationPartnerPlan() {
  return Client.get(GET_URI, {
    baseURL: BASE_URL,
  })
}
