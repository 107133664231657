import { useQuery } from '@tanstack/react-query'

import Client from '../../lib/client'

/**
 * @typedef {Object} GetQuery
 * @property {Object} responseData
 */

/**
 * @param {Object} args
 * @param {String} args.endpoint
 * @param {String,String[]} args.queryKey - see https://tanstack.com/query/v3/docs/react/guides/query-keys
 * @param {Object} options - see "Options" at https://react-query.tanstack.com/reference/useQuery
 *
 * @returns {GetQuery} also includes the response detailed at https://react-query.tanstack.com/reference/useQuery
 */
export default function useGetQuery({ endpoint, queryKey }, options) {
  const actualQueryKey = Array.from([queryKey]).flat()

  const {
    data = {},
    ...rest
  } = useQuery(actualQueryKey,
               () => Client.get(endpoint),
               options)

  const responseData = data?.data || {}

  return {
    ...rest,
    responseData,
  }
}
