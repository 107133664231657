import Flagsmith from 'flagsmith'


// From the docs: "getFlags()=> Promise<IFlags> Trigger a manual fetch of the environment features,
// If a user is identified it will fetch their features. Resolves a promise when the flags are updated."
//
// See: https://docs.flagsmith.com/clients/javascript
export default function handleFlagsmithUser(user) {
  if ( user.plan_name === 'basic' ) {
    return Flagsmith.logout()
  }

  return Flagsmith.identify(user.uuid)
}
