import { put, takeLatest } from 'redux-saga/effects'

import createActionCreator from 'utils/redux'
import { setVisible } from 'reducers/elements/modals/funnelWidgetPreviewer'

export const SHOW = `${ __filename }/SHOW`
export const CLOSE = `${ __filename }/CLOSE`

export const show = createActionCreator(SHOW)
export const close = createActionCreator(CLOSE)

export function * sagaShow() {
  yield put(setVisible(true))
}

export function * sagaClose() {
  yield put(setVisible(false))
}

export const watchers = {
  * show() {
    yield takeLatest(SHOW, sagaShow)
  },
  * close() {
    yield takeLatest(CLOSE, sagaClose)
  },
}
