import createActionCreator from 'utils/redux'
import { SET_SUBSCRIPTIONS_SUCCESS } from 'reducers/checkout'
import { CANCEL_SUBSCRIPTION_SUCCESS } from 'reducers/settings'
import planFinder from 'utils/planFinder'

export const CHANGE_PLAN = 'CHANGE_PLAN'
export const CHANGE_PLAN_SUCCESS = 'CHANGE_PLAN_SUCCESS'
export const REACTIVATE_SUBSCRIPTION_SUCCESS = 'REACTIVATE_SUBSCRIPTION_SUCCESS'

export const initState = {
  current_plan: {},
  plans: {
    annual_bill: [],
    monthly_bill: [],
  },
}

export default (state = initState, action) => {
  switch (action.type) {
    case CANCEL_SUBSCRIPTION_SUCCESS:
    case CHANGE_PLAN_SUCCESS: {
      // eslint-disable-next-line no-unused-vars
      const { billing_info, ...plan } = action.payload
      return { ...plan }
    }
    case SET_SUBSCRIPTIONS_SUCCESS: return planFinder.idUpdatePlan(action.payload.pricingInfo, state)
    default: return state
  }
}

export const changePlan = createActionCreator(CHANGE_PLAN)
export const changePlanSuccess = createActionCreator(CHANGE_PLAN_SUCCESS)
export const reactivateSubscriptionSuccess = createActionCreator(REACTIVATE_SUBSCRIPTION_SUCCESS)
