import { put, takeLatest } from 'redux-saga/effects'

import createActionCreator from 'utils/redux'
import { setVisible } from 'reducers/elements/modals/planPurchaseFail'

export const CLICK_CLOSE_BUTTON = `${ __filename }/CLICK_CLOSE_BUTTON`

export const clickCloseButton = createActionCreator(CLICK_CLOSE_BUTTON)

export function * sagaClickCloseButton() {
  yield put(setVisible(false))
}

export const watchers = {
  * clickCloseButton() {
    yield takeLatest(CLICK_CLOSE_BUTTON, sagaClickCloseButton)
  },
}
