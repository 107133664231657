// This simple wrapper is provided solely as a convenience so that
// callers can cleanly invoke the Google TagManager API
// (https://developers.google.com/tag-platform/tag-manager/web/datalayer)
// without regard to whether window.dataLayer exists.


export default {
  push(payload) {
    window.dataLayer?.push(payload)
  },
}
