import { useMemo } from 'react'

import { useIntercomAPI } from '../IntercomAPIProvider'
import { useUserflowAPI } from '../UserflowAPIProvider'


export default function useUserTracker() {
  const intercom = useIntercomAPI()
  const userflow = useUserflowAPI()

  const apis = useMemo(() => [
    intercom,
    userflow,
  ], [intercom, userflow])

  return useMemo(() => ({
    signIn({ hasWebsite, user }) {
      apis.forEach((api) => api.signIn({
        hasWebsite,
        user,
      }))
    },

    signOut() {
      apis.forEach((api) => api.signOut())
    },

    trackEvent(event) {
      apis.forEach((api) => api.trackEvent(event))
    },

    updateUser(attrs) {
      apis.forEach((api) => api.updateUser(attrs))
    },
  }), [apis])
}

