import { call, put, takeLatest, select } from 'redux-saga/effects'

import {
  subscriptionsCancel,
  billingInfo,
} from 'constants/api'

import API from 'utils/api'
import { getPlanType } from 'utils/selectors'
import planFinder from 'utils/planFinder'

import {
  getBillingInfoSuccess,
  getBillingInfoFailure,
  GET_BILLING_INFO,
} from 'reducers/billing'

import {
  CANCEL_SUBSCRIPTION,
  cancelSubscriptionSuccess,
} from 'reducers/settings'

import { setData as setBillingData } from 'reducers/responses/billing'

import { apiCallBasic } from './apiCall'


export function* cancelSubscription({ payload } = {}) {
  const { reason = '' } = payload

  try {
    const planType = yield select(getPlanType)
    const result = yield call(API.putData, subscriptionsCancel(planType), { reason })

    yield put(cancelSubscriptionSuccess(planFinder.getPayloadPlanVersion(result)))
    yield put(setBillingData(result.billing_info))
  } catch (e) {
    console.error('ERROR: %o', e)
    if ( e.json ) {
      e.json.json().then(({ error }) => console.log('ERROR: %o', error))
    }
  }
}

export function* watchGetBillingInfo() {
  yield takeLatest(GET_BILLING_INFO, getBillingInfo)
}

export const getBillingInfo = () => {
  return apiCallBasic(null, API.getData, billingInfo, getBillingInfoSuccess, {
    onFailure: getBillingInfoFailure,
  })
}

export function* watchCancelSubscription() {
  yield takeLatest(CANCEL_SUBSCRIPTION, cancelSubscription)
}
