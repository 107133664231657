import createActionCreator from 'utils/redux'

export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION'
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS'
export const CREDIT_CARD_REACTIVATE = 'CREDIT_CARD_REACTIVATE'
export const SUBMIT_FORM = 'SUBMIT_FORM'

export const cancelSubscription = createActionCreator(CANCEL_SUBSCRIPTION)
export const cancelSubscriptionSuccess = createActionCreator(CANCEL_SUBSCRIPTION_SUCCESS)
export const submitForm = createActionCreator(SUBMIT_FORM)
