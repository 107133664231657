import createActionCreator from 'utils/redux'


export const TOKEN_VERIFY = 'TOKEN_VERIFY'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const TOKEN_VERIFY_SUCCESS = 'TOKEN_VERIFY_SUCCESS'
export const PWD_API_SUCCESS = 'PWD_API_SUCCESS'
export const PWD_API_FAIL = 'PWD_API_FAIL'
export const EMPTY_PWD_MSG = 'EMPTY_PWD_MSG'
export const TOKEN_VERIFY_FAIL = 'TOKEN_VERIFY_FAIL'

export const tokenVerify = createActionCreator(TOKEN_VERIFY)
export const resetPassword = createActionCreator(RESET_PASSWORD)
export const tokenVerifySuccess = createActionCreator(TOKEN_VERIFY_SUCCESS)
export const sendApiSuccess = createActionCreator(PWD_API_SUCCESS)
export const sendApiFail = createActionCreator(PWD_API_FAIL)
export const tokenVerifyFail = createActionCreator(TOKEN_VERIFY_FAIL)
export const emptyPwdMsg = createActionCreator(EMPTY_PWD_MSG)
