import { call, put, takeLatest, select } from 'redux-saga/effects'
import {
  startSubmit,
  stopSubmit,
  initialize,
} from 'redux-form'

import consentLogsFormIds from 'constants/formIds/consentLogs'
import {
  getConsentLogsAsRequestBody,
  getConsentLogsAsFormData,
} from 'selectors/forms/consentLogs'
import { getWebsiteIdFromParam } from 'utils/selectors'
import createActionCreator from 'utils/redux'
import api from 'utils/api'
import errorArrayGenerator from 'utils/errorArrayGenerator'
import { downloadCookieConsentLogs } from 'constants/api'
import { apiFailureCookieConsentLogs } from 'reducers/ui'
import { updateSuccess } from 'reducers/panels/consentLogs'

export const HANDLE_DID_MOUNT = `${ __filename }/HANDLE_DID_MOUNT`
export const SUBMIT           = `${ __filename }/SUBMIT`

export const handleDidMount = createActionCreator(HANDLE_DID_MOUNT)
export const submit         = createActionCreator(SUBMIT)

export function * sagaHandleDidMount() {
  try {
    const initialData = yield select(getConsentLogsAsFormData)
    yield put(initialize(consentLogsFormIds.name, initialData))
    // clear error message
    yield put(apiFailureCookieConsentLogs(null))
  } catch (e) {
    console.error(e)
  }
}

export function * sagaSubmit() {
  try {
    yield put(startSubmit(consentLogsFormIds.name))
    yield put(updateSuccess(false))
    const { startDate, endDate } = yield select(getConsentLogsAsRequestBody)
    const websiteId = yield select(getWebsiteIdFromParam)
    const resp = yield call(api.getData, downloadCookieConsentLogs(startDate, endDate, websiteId))
    if (resp === '') {
      yield put(apiFailureCookieConsentLogs(errorArrayGenerator({ error:'No consent logs found' })))
    } else {
      yield put(apiFailureCookieConsentLogs(null))
      yield put(updateSuccess(true))
    }
  } catch (e) {
    console.error(e)
  } finally {
    yield put(stopSubmit(consentLogsFormIds.name))
  }
}

export const watchers = {
  * handleDidMount() {
    yield takeLatest(HANDLE_DID_MOUNT, sagaHandleDidMount)
  },
  * submit() {
    yield takeLatest(SUBMIT, sagaSubmit)
  },
}
