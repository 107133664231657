const DAY = 1000 * 60 * 60 * 24
const moment = require('moment')

const dueDate = (timestamp) => {
  const dateDiffInDays = (d1, d2) => {
    const utc1 = Date.UTC(d1.getFullYear(), d1.getMonth(), d1.getDate())
    const utc2 = Date.UTC(d2.getFullYear(), d2.getMonth(), d2.getDate())
    return Math.floor((utc2 - utc1) / DAY)
  }

  return dateDiffInDays(new Date(), new Date(timestamp * 1000))
}

const addDays = (days) => {
  const date = new Date()
  date.setDate(date.getDate() + days)
  return date
}

const formatDate = (date) => {
  const values = date.split(/[^0-9]/)
  return new Date(parseInt(values[0], 10), parseInt(values[1], 10) - 1, parseInt(values[2], 10), parseInt(values[3], 10), parseInt(values[4], 10), parseInt(values[5], 10))
}

const transferTimezone = (timestamp, format) => {
  const formattedDate = format(timestamp, { timeZone: 'America/New_York' }).split('/')
  return { month: formattedDate[0] - 1, year: formattedDate[2] }
}

const getViewDays = (timestamp, format) => {
  const monthTimestamps = []
  const viewStart = transferTimezone(timestamp * 1000, format)
  const startDate = new Date(viewStart.year, viewStart.month, 15)
  const today = transferTimezone(Date.now(), format)
  const endDate = new Date(today.year, today.month, 15)
  const numberOfMonths = (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth()) + 1
  for (let m = 0; m < numberOfMonths; m += 1) {
    monthTimestamps.unshift(Date.UTC(viewStart.year, viewStart.month + m, 15))
  }
  return monthTimestamps
}

const getMidOfMonth = (timestamp, format) => {
  const formattedDate = transferTimezone(timestamp, format)
  const date = new Date(formattedDate.year, formattedDate.month, 15)
  return Date.UTC(date.getFullYear(), date.getMonth(), 15)
}

const getNow = () => new Date()

const getDateFromMoment = (outputFormat, days = 0, inputMoment = moment()) => {
  const result = inputMoment.add(days, 'day')
  return outputFormat ? result.format(outputFormat) : result
}

const getDiffMoment = (day, inputMoment = moment()) => inputMoment.diff(day)

const getDiffDay = (minuend, subtrahend, shouldShowFloat = false) => minuend.diff(subtrahend, 'days', shouldShowFloat)

const pickValidDayRange = (minDay, maxDay) => (day) => {
  const today = getDateFromMoment()
  const diffDay = getDiffDay(day, today, true)
  return diffDay >= maxDay || diffDay <= minDay
}

const getInitStartDate = (outputFormat) => getDateFromMoment(outputFormat, -14,  moment())

const getInitEndDate = (outputFormat) => getDateFromMoment(outputFormat, 0,  moment())

export default {
  dueDate,
  addDays,
  formatDate,
  getViewDays,
  getMidOfMonth,
  getNow,
  getDateFromMoment,
  getDiffMoment,
  getDiffDay,
  pickValidDayRange,
  getInitStartDate,
  getInitEndDate,
}
