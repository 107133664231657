import { useQuery } from '@tanstack/react-query'

import Client from '../../lib/client'

import getCacheKey from './getCacheKey'

const GET_URL = 'v1/websites/:websiteID'


/**
 * @param {Object} args
 * @param {Number|String} args.websiteID - the ID or UUID of the desired website
 *
 * @returns {Object} see https://react-query.tanstack.com/reference/useQuery
 */
export default function useWebsiteQuery({ websiteID }, options) {
  const url = GET_URL.replace(':websiteID', websiteID)

  const cacheKey = getCacheKey(websiteID)
  const enabled = Boolean(websiteID)

  const {
    data = {},
    ...others
  } = useQuery(cacheKey,
               () => Client.get(url),
               {
                 enabled,
                 ...options,
               })

  return {
    ...others,
    website: data.data,
  }
}
