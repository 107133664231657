import { combineReducers } from 'redux'

import funnelWidgetPreviewer from './funnelWidgetPreviewer'
import planPurchaseSuccess from './planPurchaseSuccess'
import planPurchaseFail from './planPurchaseFail'
import deleteWebsite from './deleteWebsite'
import deleteCookie from './deleteCookie'
import changeWebsiteUrl from './changeWebsiteUrl'
import themeSaver from './themeSaver'
import deleteTheme from './deleteTheme'
import deleteAccount from './deleteAccount'
import discardChangeSuccess from './discardChangeSuccess'
import policyMigrator from './policyMigrator'


export default combineReducers({
  funnelWidgetPreviewer,
  planPurchaseSuccess,
  planPurchaseFail,
  deleteWebsite,
  deleteCookie,
  changeWebsiteUrl,
  themeSaver,
  deleteTheme,
  deleteAccount,
  discardChangeSuccess,
  policyMigrator,
})
