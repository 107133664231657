// TODO: kill lodash
import _ from 'lodash'
import { createSelector } from 'reselect'
import {
  getFormValues,
  isSubmitting,
} from 'redux-form'

import consentLogsFormIds from 'constants/formIds/consentLogs'

import localeDate from 'utils/localeDate'

export const getValues       = getFormValues(consentLogsFormIds.name)
export const getIsSubmitting = isSubmitting(consentLogsFormIds.name)

export const getSubmitButtonDisabled = createSelector(
  getIsSubmitting,
  (isSubmtting) => isSubmtting,
)

export const getConsentLogsAsFormData = createSelector(
  () => {
    const initialData = {}
    _.set(initialData, consentLogsFormIds.fields.startDate, localeDate.getInitStartDate('YYYY/MM/DD'))
    _.set(initialData, consentLogsFormIds.fields.endDate, localeDate.getInitEndDate('YYYY/MM/DD'))
    return initialData
  }
)

export const getConsentLogsAsRequestBody = createSelector(
  getValues,
  (values) => ({
    startDate: _.get(values, consentLogsFormIds.fields.startDate),
    endDate: _.get(values, consentLogsFormIds.fields.endDate),
  })
)
