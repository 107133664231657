import useGetQuery from 'hooks/useGetQuery'

const GET_URI = 'v1/users/info'
const QUERY_KEY = ['user-info']


export default function useUserInfoQuery() {
  const {
    responseData,
    ...otherReturnValues
  } = useGetQuery({
    endpoint: GET_URI,
    queryKey: QUERY_KEY,
  })

  const {
    excluded,
    ...user
  } = responseData

  return {
    ...otherReturnValues,
    excluded,
    user,
  }
}
