
import createActionCreator from 'utils/redux'
import { SET_SUBSCRIPTIONS_SUCCESS } from 'reducers/checkout'
import { CANCEL_SUBSCRIPTION_SUCCESS } from 'reducers/settings'
import {
  CHANGE_PLAN_SUCCESS,
  REACTIVATE_SUBSCRIPTION_SUCCESS,
} from 'reducers/plan'

export const GET_BILLING_INFO = 'GET_BILLING_INFO'
export const GET_BILLING_INFO_SUCCESS = 'GET_BILLING_INFO_SUCCESS'
export const GET_BILLING_INFO_FAILURE = 'GET_BILLING_INFO_FAILURE'

export const initState = {
  current_period_start: null,
  current_period_end: null,
  plan_description: null,
  subscription_status: null,
  pricing:  null,
  credit_card: {
    last4: null,
    exp_month: null,
    exp_year:  null,
  },
  features: [],
}

export default (state = initState, action) => {
  switch (action.type) {
    case SET_SUBSCRIPTIONS_SUCCESS: return action.payload.response
    case REACTIVATE_SUBSCRIPTION_SUCCESS:
    case GET_BILLING_INFO_SUCCESS: return action.payload
    case GET_BILLING_INFO_FAILURE: return initState
    case CANCEL_SUBSCRIPTION_SUCCESS:
    case CHANGE_PLAN_SUCCESS: return action.payload.billing_info
    default: return state
  }
}

export const getBillingInfo = createActionCreator(GET_BILLING_INFO)
export const getBillingInfoSuccess = createActionCreator(GET_BILLING_INFO_SUCCESS)
export const getBillingInfoFailure = createActionCreator(GET_BILLING_INFO_FAILURE)
