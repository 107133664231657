import React from 'react'
import { Outlet } from 'react-router-dom'

import IntercomAPIProvider from './IntercomAPIProvider'
import UserflowAPIProvider from './UserflowAPIProvider'


export default function UserTrackerProvider() {
  return (
    <IntercomAPIProvider>
      <UserflowAPIProvider>
        <Outlet />
      </UserflowAPIProvider>
    </IntercomAPIProvider>
  )
}


