import React, {
  useEffect,
} from 'react'

import PropTypes from 'prop-types'
import Flagsmith from 'flagsmith'

import FeatureFlagsContext from './FeatureFlagsContext'
import initFlagsmith from './lib/initFlagsmith'


// There's actually a FlagsmithProvider, but when we import
// flagsmith/react, everything blows up...it won't even build because
// the resolver can't resolve 'react'. No matter what I do, I can't
// get it to work, despite the fact that it works just fine in an
// off-the-shelf NextJS or CRA app. I got sick of fighting with it, so I
// just wrote this simple wrapper.
//
export default function FeatureFlagsProvider({ children }) {
  useEffect(() => {
    initFlagsmith()
  }, [])

  return (
    <FeatureFlagsContext.Provider
      value={ Flagsmith }
    >
      { children }
    </FeatureFlagsContext.Provider>
  )
}

FeatureFlagsProvider.propTypes = {
  children: PropTypes.node,
}
