import React from 'react'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider as ReduxProvider } from 'react-redux'

import {
  BuildVersionChecker,
  GoneFishin,
  ReactQueryClientProvider,
  Toaster,
  initializeSentry,
} from '@termly_web/common'

import {
  persistor,
  store,
} from 'store'

import fetchHealth from 'utils/fetchHealth'

import DashboardIntlProvider from 'components/DashboardIntlProvider'
import FeatureFlagsProvider from 'components/FeatureFlagsProvider'

import Router from './Router'

import './styles.scss'

initializeSentry({ store })


export default function App() {
  const preferredLocales = window.navigator.languages

  return (
    <FeatureFlagsProvider>
      <ReactQueryClientProvider>
        <ReduxProvider
          store={ store }
        >
          <PersistGate
            loading={ null }
            persistor={ persistor }
          >
            <DashboardIntlProvider
              preferredLocales={ preferredLocales }
            >
              <BuildVersionChecker />
              <GoneFishin
                fetchHealth={ fetchHealth }
              >
                <Router />
              </GoneFishin>
              <Toaster />
            </DashboardIntlProvider>
          </PersistGate>
        </ReduxProvider>
      </ReactQueryClientProvider>
    </FeatureFlagsProvider>
  )
}
