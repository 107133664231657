import { createSelector } from 'reselect'

import Config from 'config'

import {
  BASIC,
  PRO,
  PRO_PLUS,
} from 'constants/variables'

import name from './name'


/**
 * @function
 */
export const selectIsFreemium =
  createSelector(selectAuthentication, ({ freemium }) => freemium)

/**
 * @function
 */
export const selectIsGuest =
  createSelector(selectAuthentication, ({ guest }) => guest)

/**
 * @function
 */
export const selectFirstName =
  createSelector(selectAuthentication, (auth) => {
    return auth.first_name || ''
  })

/**
 * @function
 */
export const selectLastName =
  createSelector(selectAuthentication, (auth) => {
    return auth.last_name || ''
  })

/**
 * @function
 */
export const selectEmail =
  createSelector(selectAuthentication, ({ email }) => email || '')

/**
 * @function
 */
export const selectCanPublish =
  createSelector(selectAuthentication,
                 ({ can_publish }) => can_publish)

/**
 * @function
 */
export const selectForcePasswordChange =
  createSelector(selectAuthentication, (auth) => {
    return auth.must_change_password
  })

/**
 * @function
 */
export const selectCurrentPlanName =
  createSelector(selectAuthentication, (auth) => auth.plan_name)


// TODO: This doesn't really belong here. A selector should be
// concerned with retrieving data and consolidating structures, not
// altering the data to suit our display needs. This was simply moved
// over from utils/selectors.js.
/**
 * @function
 */
export const selectDisplayPlanName =
  createSelector(selectCurrentPlanName, (planName) => {
    return planName && capitalFirst(Config.plans[planName])
  })

// TODO: Kill this with TER-12231.
/**
 * @function
 */
export const selectIsOverConsentQuota =
  createSelector(selectCurrentPlanName,
                 ({ is_over_consent_quota }) => is_over_consent_quota)

/**
 * @function
 */
export const selectIsProPlus =
  createSelector(selectCurrentPlanName, (planName) => {
    return planName === PRO_PLUS
  })

/**
 * @function
 */
export const selectIsBasicUser =
  createSelector(selectCurrentPlanName, (planName) => {
    return planName === BASIC
  })

/**
 * @function
 */
export const selectIsProUser =
  createSelector(selectCurrentPlanName, (planName) => {
    return planName === PRO
  })

/**
 * @function
 */
export const selectIsPaidUser =
  createSelector(selectCurrentPlanName, (planName) => {
    return ( planName === PRO_PLUS || planName === PRO )
  })

/**
 * @function
 */
export const selectIsTrialUser =
  createSelector(selectAuthentication, (auth) => auth.is_trial_user)

/**
 * @function
 */
export const selectError =
  createSelector(selectAuthentication, ({ error }) => error)

/**
 * @function
 */
export const selectExcludedFeatures =
  createSelector(selectAuthentication, ({ excluded }) => excluded || {})

/**
 * @function
 */
export const selectExcludedPages =
  createSelector(selectExcludedFeatures, ({ pages }) => pages || [])

// TODO: Ideally, we should not have to export this. We'll have to
// root through the app and see where it is being used and figure out
// a way to prune those uses.
export function selectAuthentication(state) {
  return state[name]
}

function capitalFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
