import {
  call,
  takeLatest,
} from 'redux-saga/effects'

import History from 'store/history'

import Paths from 'constants/paths'

import { CANCEL_SUBSCRIPTION_SUCCESS } from 'reducers/settings'

export function* sagaCancelSubscriptionSuccess() {
  yield call(History.push, Paths.PLAN_INFO)
}

export const watchers = {
  *cancelSubscriptionSuccess() {
    yield takeLatest(CANCEL_SUBSCRIPTION_SUCCESS, sagaCancelSubscriptionSuccess)
  },
}
