import createActionCreator from 'utils/redux'

// TODO: This file is involved in a number of circular dependencies, e.g.:
//
// reducers/ui.js > reducers/billing.js > pkg/authentication/index.js
// > pkg/authentication/store/index.js >
// pkg/authentication/store/sagas.js > sagas/apiCall.js
//
// Basically, look at the list of reducers/* dependencies we declare in this
// file...each one of them sets up another circular dependency. Fixing this
// would involve inverting the relationship between this file and our dependencies.
// Rather than this reducer reponding to a metric ton of external actions, we should
// either:
// 1. Export some actions so that our dependnecies can consume, or better yet
// 2. Get rid of this whole reducer and make each page handle its own errors. I
//    say more about this below.

import {
  CANCEL_SUBSCRIPTION_SUCCESS,
} from 'reducers/settings'

import { SET_SUBSCRIPTIONS } from 'reducers/checkout'

import {
  PWD_API_SUCCESS,
  TOKEN_VERIFY_FAIL,
  PWD_API_FAIL,
  EMPTY_PWD_MSG,
} from 'reducers/resetPwd'
import {
  CREATE_COOKIE_SUCCESS,
  UPDATE_COOKIE_SUCCESS,
} from 'reducers/cookieReport'
import {
  REACTIVATE_SUBSCRIPTION_SUCCESS,
} from 'reducers/plan'

const TOGGLE_DIALOG = 'TOGGLE_DIALOG'
const API_FAILURE = 'API_FAILURE'
const API_FAILURE_COOKIE_REPORT = 'API_FAILURE_COOKIE_REPORT'
const API_FAILURE_COOKIE_CONSENT_LOGS = 'API_FAILURE_COOKIE_CONSENT_LOGS'
const SHOW_ERROR_UPLOAD = 'SHOW_ERROR_UPLOAD'
const TOGGLE_TOUCHED_ERROR = 'TOGGLE_TOUCHED_ERROR'
const RESET_ERROR = 'RESET_ERROR'
const TOGGLE_BANNER = 'TOGGLE_BANNER'
const TOGGLE_TOOLTIP = 'TOGGLE_TOOLTIP'
const TOGGLE_LOADING = 'TOGGLE_LOADING'
const CHANGE_PLAN_FAILURE = 'CHANGE_PLAN_FAILURE'

export const initialState = {
  dialog: null,
  loading: {
    index: true,
    image: false,
    form: false,
    preview: false,
    auth: false,
    detect: false,
    pageview: false,
    scanDomain: false,
  },
  error: null,
  imageFile: {},
  errorUpload: null,
  multiTouched: {
    0: false,
    1: false,
  },
  showBanner: false,
  showTooltip: false,
  resetPwd: {
    msg: null,
    error: null,
  },
}

// TODO: This reducer really feels like it's doing way too much. I
// don't understand the benefit of having one centralized location in
// the store for containing all of the error messaging for the entire
// app. It's not like we have a super-consistent way of displaying
// errors like a modal--it's all rather ad hoc.  This approach spreads
// out the effects of a saga across a wide area of our codebase making
// it difficult to understand how it all fits together.
//
export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_DIALOG: return {
      ...state,
      dialog: action.payload || null,
      error: null,
      errorUpload: null,
      multiTouched: initialState.multiTouched,
    }
    case CANCEL_SUBSCRIPTION_SUCCESS: return {
      ...state,
      dialog: 'CancelSuccess',
    }
    case API_FAILURE: return {
      ...state,
      error: action.payload,
    }
    case API_FAILURE_COOKIE_REPORT: return {
      ...state,
      errorCookieReport: action.payload,
    }
    case API_FAILURE_COOKIE_CONSENT_LOGS: return {
      ...state,
      errorCookieConsentLogs: action.payload,
    }
    case TOGGLE_LOADING: return {
      ...state,
      loading: {
        ...state.loading,
        [action.payload.element || action.payload]: !!action.payload.isLoading,
      },
    }
    case SHOW_ERROR_UPLOAD: return {
      ...state,
      errorUpload: action.payload || null,
    }
    case TOGGLE_TOUCHED_ERROR: return {
      ...state,
      multiTouched: {
        ...state.multiTouched,
        [action.payload]: true,
      },
    }
    case RESET_ERROR:
    case SET_SUBSCRIPTIONS:
    case CREATE_COOKIE_SUCCESS:
    case UPDATE_COOKIE_SUCCESS: return {
      ...state,
      error: null,
    }
    case TOGGLE_BANNER: return {
      ...state,
      showBanner: action.payload || false,
    }
    case TOGGLE_TOOLTIP: return {
      ...state,
      showTooltip: action.payload || false,
    }
    case PWD_API_SUCCESS: return {
      ...state,
      resetPwd: { ...action.payload, error: null },
    }
    case TOKEN_VERIFY_FAIL:
    case PWD_API_FAIL: return {
      ...state,
      resetPwd: { msg: null, error: action.payload },
    }
    case EMPTY_PWD_MSG: return {
      ...state,
      resetPwd: initialState.resetPwd,
    }
    case CHANGE_PLAN_FAILURE: return {
      ...state,
      dialog: 'CheckoutResponseDialog',
      error: action.payload,
    }
    case REACTIVATE_SUBSCRIPTION_SUCCESS: return {
      ...state,
      dialog: 'ReactivateSuccess',
    }
    default: return state
  }
}

export const toggleDialog = createActionCreator(TOGGLE_DIALOG)
export const apiFailure = createActionCreator(API_FAILURE)
export const apiFailureCookieReport = createActionCreator(API_FAILURE_COOKIE_REPORT)
export const apiFailureCookieConsentLogs = createActionCreator(API_FAILURE_COOKIE_CONSENT_LOGS)
export const toggleLoading = createActionCreator(TOGGLE_LOADING)
export const showErrorUpload = createActionCreator(SHOW_ERROR_UPLOAD)
export const toggleTouchedError = createActionCreator(TOGGLE_TOUCHED_ERROR)
export const resetError = createActionCreator(RESET_ERROR)
export const toggleBanner = createActionCreator(TOGGLE_BANNER)
export const changePlanFailure = createActionCreator(CHANGE_PLAN_FAILURE)
export const toggleTooltip = createActionCreator(TOGGLE_BANNER)
