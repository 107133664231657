import { call, delay, put, select, takeLatest } from 'redux-saga/effects'

import { initialize } from 'redux-form'

import history from 'store/history'

import {
  COOKIE_REPORT_INIT,
  CREATE_COOKIE,
  UPDATE_COOKIE,
  DELETE_COOKIE,
  UPDATE_COOKIE_CATEGORY,
  EXPORT_CONSENT_LOGS,
  getCookieReportSuccess,
  createCookieSuccess,
  updateCookieSuccess,
  deleteCookieSuccess,
  updateCookieCategorySuccess,
} from 'reducers/cookieReport'

import {
  UPDATE_COOKIE_POLICY,
  updateCookiePolicySuccess,
} from 'reducers/dashboard'

import {
  apiFailure,
  apiFailureCookieReport,
  apiFailureCookieConsentLogs,
  toggleLoading,
} from 'reducers/ui'

import { setData as setWebsiteData } from 'reducers/responses/website'

import errorArrayGenerator from 'utils/errorArrayGenerator'
import API from 'utils/api'
import PATHS from 'constants/paths'
import {
  website,
  getReport,
  postCookie,
  putCookie,
  downloadCookieConsentLogs,
  publishCookiePolicy,
} from 'constants/api'
import {
  getCookieReport,
  getPathname,
  getWebsiteIdFromParam,
} from 'utils/selectors'


import apiCall from './apiCall'


export function* cookieReportInitialize(action) {
  try {
    const websiteInfo = yield call(API.getData, website(action.payload))
    yield put(setWebsiteData(websiteInfo))
    if (websiteInfo.current_report_id) {
      const reportData = yield call(API.getData, getReport(websiteInfo.current_report_id)) // websiteInfo.current_report_id
      if (reportData.status === 'scanning') {
        yield call(history.push, PATHS.DASHBOARD)
      } else {
        yield put(getCookieReportSuccess(reportData))
      }
    } else {
      yield call(history.push, PATHS.DASHBOARD)
    }
    let isScanning = websiteInfo.scan_status === 'scanning'
    let currentPath = yield select(getPathname)
    while (isScanning && currentPath === PATHS.COOKIE_REPORT(action.payload.id)) {
      currentPath = yield select(getPathname)
      yield delay(1000)
      const websiteInfoData = yield call(API.getData, website(action.payload.id))
      if ( websiteInfoData.scan_status === 'scan_finish' ) {
        yield put(setWebsiteData(websiteInfoData))
        isScanning = false
      }
    }
  } catch (e) {
    const error = yield e.json.json()
    if (error.error.includes('find Website')) {
      yield call(history.push, PATHS.DASHBOARD)
    } else {
      yield put(apiFailure(errorArrayGenerator(error)))
    }
  }
}

export function* addCookie(action) {
  try {
    yield put(toggleLoading({ element: 'form', isLoading: true }))
    const { createAnother = false } = yield select(getCookieReport)
    const { reportId, ...otherValues } = action.payload
    const { cookie_name, first_found = '', ...values } = otherValues
    const createCookieResponse = yield call(API.postData, postCookie(reportId), { ...values, name: cookie_name, url: first_found }, 'json')
    if (createAnother) {
      yield put(initialize('addCookieForm', { reportId, category: 'essential', tracker_type: 'http_cookie', country: '' }))
    } else {
      const websiteId = yield select(getWebsiteIdFromParam)
      yield call(history.push, PATHS.COOKIE_REPORT(websiteId))
    }
    yield put(createCookieSuccess(createCookieResponse))
  } catch (e) {
    const error = yield e.json.json()
    yield put(apiFailure(errorArrayGenerator(error)))
  } finally {
    yield put(toggleLoading('form'))
  }
}

export function* updateCookie(action) {
  try {
    yield put(toggleLoading({ element: 'form', isLoading: true }))
    const { type, payload } = action
    const { reportId, id, ...otherData } = payload
    const { cookie_name, first_found = '', ...values } = otherData
    const putData = type === UPDATE_COOKIE_CATEGORY ? { ...values } : { ...values, name: cookie_name, url: first_found }
    const updateCookieResponse = yield call(API.putData, putCookie(reportId, id), putData, 'json')
    yield put(type === UPDATE_COOKIE ? updateCookieSuccess(updateCookieResponse) : updateCookieCategorySuccess(updateCookieResponse))
    const websiteId = yield select(getWebsiteIdFromParam)
    if (type === UPDATE_COOKIE_CATEGORY) {
      yield* cookieReportInitialize({ payload: websiteId })
    } else {
      yield call(history.push, PATHS.COOKIE_REPORT(websiteId))
    }
  } catch (e) {
    const error = yield e.json.json()
    yield put(apiFailureCookieReport(errorArrayGenerator(error)))
  } finally {
    yield put(toggleLoading('form'))
  }
}

export function* exportConsentLogs(action) {
  try {
    yield put(toggleLoading({ element: 'form', isLoading: true }))
    const websiteId = yield select(getWebsiteIdFromParam)
    const { payload: { startDate, endDate } } = action
    const resp = yield call(API.getData, downloadCookieConsentLogs(startDate, endDate, websiteId))

    if (resp === '') {
      yield put(apiFailureCookieConsentLogs(errorArrayGenerator({ error:'No consent logs found' })))
    } else {
      yield put(apiFailureCookieConsentLogs(null))
    }
  } catch (e) {
    const error = yield e.json.json()
    yield put(apiFailureCookieConsentLogs(errorArrayGenerator(error)))
  } finally {
    yield put(toggleLoading('form'))
  }
}

export function* deleteCookie(action) {
  try {
    yield put(toggleLoading({ element: 'form', isLoading: true }))
    const { reportId, cookieId: id } = action.payload
    const deleteCookieResponse = yield call(API.deleteData, putCookie(reportId, id))
    yield put(deleteCookieSuccess(deleteCookieResponse))
    const websiteId = yield select(getWebsiteIdFromParam)
    yield call(history.push, PATHS.COOKIE_REPORT(websiteId))
  } catch (e) {
    const error = yield e.json.json()
    yield put(apiFailure(errorArrayGenerator(error)))
  } finally {
    yield put(toggleLoading('form'))
  }
}

export function* watchCookieReportInit() {
  yield takeLatest(COOKIE_REPORT_INIT, cookieReportInitialize)
}

export function* watchCreateCookie() {
  yield takeLatest(CREATE_COOKIE, addCookie)
}

export function* watchUpdateCookie() {
  yield takeLatest(UPDATE_COOKIE, updateCookie)
}

export function* watchUpdateCookieCategory() {
  yield takeLatest(UPDATE_COOKIE_CATEGORY, updateCookie)
}

export function* watchDeleteCookie() {
  yield takeLatest(DELETE_COOKIE, deleteCookie)
}

export function* updateCookiePolicy(action) {
  yield* apiCall(null, API.putData, () => publishCookiePolicy(action.payload), updateCookiePolicySuccess)
}

export function* watchUpdateCookiePolicy() {
  yield takeLatest(UPDATE_COOKIE_POLICY, updateCookiePolicy)
}

export function* watchExportConsentLogs() {
  yield takeLatest(EXPORT_CONSENT_LOGS, exportConsentLogs)
}
