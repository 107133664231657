const legacyVersion = 'v1'
const latestVersion = 'v2'


/**
 * @param {String} endPoint
 * @param {String} type - the plan type
 *
 * @returns {String}
 */
export default function checkApiVersion(endPoint, type) {
  if ( type === 'tier' || type === 'unlimited' ) {
    return endPoint
  }

  return endPoint.replace(legacyVersion, latestVersion)
}
