const filterPlanByVersion = ({ active_plans, currency_symbol, current_plan, plans }) => {
  return {
    active_plans,
    currency_symbol,
    current_plan,
    plans: plans[`version_${ window.chosenVariation }`],
  }
}

const getPayloadPlanVersion = (APIResponse) => {
  const { billing_info, ...plan } = APIResponse
  return {
    billing_info,
    ...filterPlanByVersion(plan),
  }
}

const findPlan = ({ annual_bill, monthly_bill }, plan_id) => {
  return [...annual_bill, ...monthly_bill].find((plan) => plan.id === plan_id) || {}
}

const findProPlusAnnual = ({ annual_bill }) => {
  return annual_bill.find(({ name }) => name.includes('Pro+'))
}

const findProPlusMonth = ({ monthly_bill }) => {
  return monthly_bill.find(({ name }) => name.includes('Pro+'))
}

const findStarterAnnual = ({ annual_bill }) => {
  return annual_bill.find(({ name }) => name.includes('Starter'))
}

const findStarterMonth = ({ monthly_bill }) => {
  return monthly_bill.find(({ name }) => name.includes('Starter'))
}

const findPlanByTypeAndBillingCycle = (isProPlus, isAnnual, plans) => {
  if (isProPlus && isAnnual) {
    return findProPlusAnnual(plans)
  } else if (!isProPlus && isAnnual) {
    return findStarterAnnual(plans)
  } else if (isProPlus && !isAnnual) {
    return findProPlusMonth(plans)
  } else {
    return findStarterMonth(plans)
  }
}

const idUpdatePlan = ({ is_annual: isAnnual, plan_id }, state) => {
  const current_plan = state.plans[isAnnual ? 'annual_bill' : 'monthly_bill'].find((plan) => plan.id === plan_id)
  return {
    ...state,
    current_plan,
  }
}

const findByType = (features, type) => {
  return features.find((feature) => feature.type === type) || {}
}

const featureParser = (features) => {
  const websiteInfo = findByType(features, 'website')
  const pageviewInfo = findByType(features, 'pageview')
  return { websiteInfo, pageviewInfo }
}

const isMatchedPlan = (plan, pattern) => {
  const regexp = new RegExp(pattern)
  return regexp.test(plan.name)
}

const isBasic = (plan) => {
  return isMatchedPlan(plan, /basic/)
}

const isStarter = (plan) => {
  return isMatchedPlan(plan, /Starter/)
}

const isProPlus = (plan) => {
  return isMatchedPlan(plan, /Pro\+/)
}


export default {
  filterPlanByVersion,
  getPayloadPlanVersion,
  findPlan,
  idUpdatePlan,
  featureParser,
  findByType,
  findProPlusAnnual,
  findProPlusMonth,
  findStarterAnnual,
  findStarterMonth,
  isBasic,
  isStarter,
  isProPlus,
  findPlanByTypeAndBillingCycle,
}
