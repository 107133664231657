import { URLBuilder } from '@termly_web/common'

import Config from 'config'

import checkApiVersion from 'utils/checkApiVersion'

export const baseURL = `${ Config.DOMAIN }/api`

const users          = setUrl('/users')
const websites       = setUrl('/websites')
const password       = setUrl('/password')
const plans          = setUrl('/plans')
const subscriptions  = setUrl('/subscriptions')
const documents      = setUrl('/documents')
const snippetDocs    = setUrl('/snippets/documents')
const snippetWebsite = setUrl('/snippets/websites')
const billing        = setUrl('/billing_info')
const paymentProfile = setUrl('/payment_profile')
const transactions   = setUrl('/transactions')
const auth           = setUrl('/auth')
const identities     = setUrl('/identities')
const checkHistory   = setUrl('/check_history')
const pageviews      = setUrl('/pageviews')
const styles         = setUrl('/styles')
const reports        = setUrl('/reports')
const cookieConsent  = setUrl('/cookie_consents')
const apiKey         = setUrl('/api_token')
const ipInfo         = setUrl('/ip')

export const healthURL = `${ Config.DOMAIN }/health`

export const userInfo = () => `${ users }/info`
export const websiteInfo = () => websites
export const website = (id) => `${ websites }/${ id }`
export const getSnippetWebsite = (UUID) => `${ snippetWebsite }/${ UUID }`
export const signIn = () => `${ users }/sign_in`
export const emailTokenVerify = (email, token) => `${ password }/token_verification?email=${ email }&reset_password_token=${ token }`
export const resetPwd = () => password
export const plansInfo = () => plans
export const documentInfo = (id) => `${ documents }/${ id }?status=published`
export const documentSnippet = (id) => `${ snippetDocs }/${ id }?status=published`
export const upgradeDraftPolicyToLatest = (id) => `${ documents }/${ id }/upgrade_to_latest`

export const dsarSettings = (id) => `${ websites }/${ id }/dsar_settings`

export const getWebsiteUUID = (documentUUID) => `${ snippetDocs }/${ documentUUID }/website`
export const documentDraftPreview = (id) => `${ documents }/${ id }/preview?status=draft`
export const billingInfo = () => billing
export const editChangeCard = () => `${ paymentProfile }/change_card`
export const getInvoice = (page) => `${ transactions }?page=${ page }`
export const getInvoiceDetails = (id) => `${ transactions }/${ id }`
export const getBillingHistory = () => `${ transactions }`

export const migrateDoc = (id) => `${ documents }/${ id }`
export const getDocument = (id) => `${ documents }/${ id }`
export const signInSocial = (social) => `${ auth }/${ social }`
export const signInCreateAccount = (identityId) => `${ identities }/${ identityId }/create_account`
export const signInMigrateAccount = (identityId) => `${ identities }/${ identityId }/link_account`
export const checkWebSnippet = (id) => `${ checkHistory }/website/${ id }`
export const checkWebSnippetV2 = (id) => `${ Config.DOMAIN }/api/v2/check_history/website/${ id }`
export const checkDocSnippet = (id) => `${ checkHistory }/document/${ id }`
export const checkDocSnippetV2 = (id) => `${ Config.DOMAIN }/api/v2/check_history/document/${ id }`
export const createTheme = (id) => `${ websites }/${ id }/themes`
export const applyDocumentTheme = (id) => `${ documents }/${ id }/apply_theme`
export const updateExistingTheme = (websiteId, styleId) => `${ websites }/${ websiteId }/themes/${ styleId }`
export const pageview = (date) => `${ pageviews }?start_date=${ date }`
export const websiteCustomerRequests = (id) => `${ website(id) }/customer_requests`
export const universalPPMigrate = (id) => `${ websites }/${ id }/universal_pp_migrate`
export const updateTheme = (style_id, theme_id) => `${ styles }/${ style_id }/consent_themes/${ theme_id }`
export const getReport = (id) => `${ reports }/${ id }`
export const postCookie = (id) => `${ getReport(id) }/cookies`
export const putCookie = (reportId, id) => `${ postCookie(reportId) }/${ id }`
export const getCookie = (reportId, cookieId) => `${ getReport(reportId) }/cookies/${ cookieId }`
export const deleteCookie = (reportId, cookieId) => `${ getReport(reportId) }/cookies/${ cookieId }`
export const downloadCookieConsentLogs = (startDate, endDate, websiteId) => `${ cookieConsent }/download?start_date=${ startDate }&end_date=${ endDate }&website_id=${ websiteId }`
export const publishCookiePolicy = (id) => `${ documents }/${ id }/publish_cookie_policy`
export const apiToken = (id) => `${ apiKey }?id=${ id }`
export const ip = () => ipInfo
export const sendSnippetCode = setUrl('/send_snippet_code')
export const upgradeIntent = () => `${ paymentProfile }/upgrade/intent`

/**
  query parameter for GET bannerCopies:
  language_setting <array>
  personalized_content <bool>
  running_targeted_advertising <bool>
  share_data_to_3rd_party <bool>
*/
export const bannerCopies = (websiteId, queryString) => `${ websites }/${ websiteId }/consent_widget_settings/banner_copies?${ queryString }`
export const consentWidgetSettings = (websiteId) => `${ websites }/${ websiteId }/consent_widget_settings`
export const consentThemes = (websiteId) => `${ websites }/${ websiteId }/consent_themes`
export const postCustomerRequest = (websitUUID) => `${ websites }/${ websitUUID }/customer_requests`
export const putConsentThemes = (websiteId, themeId) => `${ websites }/${ websiteId }/consent_themes/${ themeId }`

export const couponLookup = (code) => `${ plans }/coupon_codes/${ code }`


export function changePlan(planType) {
  return checkApiVersion(`${ subscriptions }/change_plan`, planType)
}

export function discardChanges(planType) {
  return checkApiVersion(`${ subscriptions }/discard_change`, planType)
}

export function setUrl(endpoint = '') {
  // This is not an ideal implementation, but it's what we had before, so
  // we'll roll with it (KISS) for now.
  return `${ baseURL }/v1${ endpoint }`
}

export function subscriptionsCancel(planType) {
  return checkApiVersion(`${ subscriptions }/cancel`, planType)
}

export function subscriptionsInfo(planType) {
  return checkApiVersion(subscriptions, planType)
}

export function editDocLink(websiteId, documentId, policyPath) {
  return URLBuilder.editWebsiteDocumentURL({
    documentId,
    websiteId,
  }, {
    policyPath,
  })
}

export function addDocLink(templateId, websiteId, policyPath) {
  return URLBuilder.newWebsiteDocumentURL({
    websiteId,
  }, {
    policyPath,
    template_id: templateId,
  })
}

export function createPolicy(templateId) {
  return URLBuilder.newDocumentURL(null, {
    template_id: templateId,
  })
}
