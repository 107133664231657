import createActionCreator from 'utils/redux'


export const DOWNGRADE_PACKAGE = 'DOWNGRADE_PACKAGE'
export const GET_TOKEN = 'GET_TOKEN'
export const SET_SUBSCRIPTIONS = 'SET_SUBSCRIPTIONS'
export const SET_SUBSCRIPTIONS_SUCCESS = 'SET_SUBSCRIPTIONS_SUCCESS'
export const UPGRADE_PACKAGE = 'UPGRADE_PACKAGE'
export const VERIFY_AND_UPGRADE = 'VERIFY_AND_UPGRADE'

export const getToken = createActionCreator(GET_TOKEN)
export const setSubscriptions = createActionCreator(SET_SUBSCRIPTIONS)
export const setSubscriptionsSuccess = createActionCreator(SET_SUBSCRIPTIONS_SUCCESS)
