import { useCallback } from 'react'

import {
  useIntercom as useIntercomBase,
} from 'react-use-intercom'


export default function useIntercom() {
  const intercom = useIntercomBase()

  const termlySignIn = useCallback(({ user, hasWebsite }) => {
    const email = ( user.guest ) ? void 0 : user.email
    const name = ( user.guest ) ? void 0 : makeName(user)

    // TODO: This plan_name check should really be done using `usePlansQuery()`
    // or `useCurrentUserPlansQuery()` that is currently found only in dashboard
    const hasUpgraded = ( user.plan_name !== 'basic' && !user.is_trial_user )

    intercom.update({
      /* eslint-disable camelcase */
      created_at: user.created_at,
      email,
      name,
      user_id: user.uuid,
      /* eslint-enable */

      customAttributes: {
        hasUpgraded,
        hasWebsite: Boolean(hasWebsite),
        userSignUpDate: user.created_at,
      },
    })
  }, [intercom])

  return {
    ...intercom,
    termlySignIn,
  }
}

function makeName(user) {
  return [user.first_name, user.last_name]
    .filter(Boolean)
    .join(' ')
}
