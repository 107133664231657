import {
  createContext,
  useContext,
} from 'react'

const IntercomAPIContext = createContext()


export default IntercomAPIContext

export function useIntercomAPI() {
  return useContext(IntercomAPIContext)
}

