import {
  call,
  take,
  takeLatest,
} from 'redux-saga/effects'

import { GtmAPI } from '@termly_web/common'

import createActionCreator from 'utils/redux'

import { GET_BILLING_INFO_SUCCESS } from 'reducers/billing'

import { getBillingInfo } from 'sagas/billing'


export const HANDLE_DID_MOUNT = `${ __filename }/HANDLE_DID_MOUNT`

export const handleDidMount = createActionCreator(HANDLE_DID_MOUNT)

export function * sagaHandleDidMount() {
  try {
    // This does seem to work if I remove the call to
    // getBillingInfo(), but that's only by happy coincidence. It's
    // not a good idea to trust that some external entity has made the
    // calls to satisfy our requirments. So, I'm firing off a(nother)
    // request to get the billing info just to be sure. Previously, we
    // were simply trying to select the data from the store, and
    // because we did this before the request was fulfilled, errors
    // were spewed.
    yield call(getBillingInfo)

    // We don't takeLatest() here because we don't want to fork and
    // keep listening...just grab the first response and be done with
    // it.
    const action = yield take(GET_BILLING_INFO_SUCCESS)

    yield call(initializeBareMetrics, action)
  } catch (e) {
    console.error(e)
  }
}

export const watchers = {
  * handleDidMount() {
    yield takeLatest(HANDLE_DID_MOUNT, sagaHandleDidMount)
  },
}

function initializeBareMetrics({ payload }) {
  const { uuid } = payload

  console.debug('Initializing BareMetrics with billing ID %o', uuid)

  window.BARECANCEL_CUSTOMER_OID = `cus_${ uuid }`

  GtmAPI.push({
    event: 'INITIALIZE_BAREMETRICS',
  })
}

export const __private = {
  initializeBareMetrics,
}
