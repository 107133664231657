import Flagsmith from 'flagsmith'

//
// Our Flagsmith architecture is documented at https://termly.slite.com/app/docs/7O3is47bcUuMGB
//

const OPTIONS = {
  // The flagsmith staging environment is for testing flagsmith deployments.
  // The production endpoint has an environmentID for each of production and
  // staging.
  api: 'https://flagsmith-api.production.termlyapi.io/api/v1/',
  cacheFlags: true,

  // We don't need to pollute the console in production
  enableLogs: ( process.env.NODE_ENV !== 'production' ),
  environmentID: process.env.FLAGSMITH_ENVIRONMENT_ID,
}


export default function initFlagsmith() {
  return Flagsmith.init(OPTIONS)
}
