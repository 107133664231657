import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'url-search-params-polyfill'
import 'date-time-format-timezone'
import 'react-dates/initialize'

import 'utils/googlePlaceAutocomplete'

import 'stylesheets/normalize.css'
import 'react-dates/lib/css/_datepicker.css'

import React from 'react'
import ReactDOM from 'react-dom'

import App from 'components/App'


ReactDOM.render(<App />, document.getElementById('app'))
