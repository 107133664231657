import { put, call, takeLatest, select } from 'redux-saga/effects'

import history from 'store/history'

import api from 'utils/api'
import { publishCookiePolicy } from 'constants/api'
import PATHS from 'constants/paths'
import createActionCreator from 'utils/redux'

import { setData } from 'reducers/responses/putPublishCookiePolicy'
import { updateCookiePolicySuccess } from 'reducers/dashboard'
import { updateIsLoadingBtnGenerateCookiePolicy } from 'reducers/panels/scanReport'

import { getCookiePolicyId } from 'selectors/responses/website'


export const HANDLE_GENERATE_COOKIE_POLICY = `${ __filename }/HANDLE_GENERATE_COOKIE_POLICY`

export const handleGenerateCookiePolicy = createActionCreator(HANDLE_GENERATE_COOKIE_POLICY)

export function * sagaHandleGenerateCookiePolicy({ payload }) {
  const {
    websiteID,
  } = payload

  try {
    yield put(updateIsLoadingBtnGenerateCookiePolicy(true))

    const cookiePolicyId = yield select(getCookiePolicyId)
    const response = yield call(api.putData, publishCookiePolicy(cookiePolicyId))
    yield put(updateCookiePolicySuccess(response))
    yield put(setData({ code: 200, data: response}))

    yield call(history.push, PATHS.COOKIE_POLICY(websiteID))
  } catch (e) {
    const error = yield e.json.json()
    yield put(setData({code: 400, data: error}))
  } finally {
    yield put(updateIsLoadingBtnGenerateCookiePolicy(false))
  }
}

export const watchers = {
  * handleGenerateCookiePolicy() {
    yield takeLatest(HANDLE_GENERATE_COOKIE_POLICY, sagaHandleGenerateCookiePolicy)
  },
}
