import { combineReducers } from 'redux'

import scanWebsite from './scanWebsite'
import policy from './policy'
import scanReport from './scanReport'
import embedPreferenceCenterButton from './embedPreferenceCenterButton'
import apiKey from './apiKey'
import consentLogs from './consentLogs'
import bannerSettings from './bannerSettings'


export default combineReducers({
  scanWebsite,
  policy,
  scanReport,
  embedPreferenceCenterButton,
  apiKey,
  consentLogs,
  bannerSettings,
})
