import React from 'react'

import Styles from './styles.scss'


export default function Required() {
  return (
    <span className={ Styles.root }>
      &nbsp;*
    </span>
  )
}
